<template>
    <file-pond
        ref="pond"
        :files="files"
        :credits="[]"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import vueFilePond, { VueFilePondComponent } from 'vue-filepond';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';

@Component({
    inheritAttrs: false,
    components: {
        FilePond: vueFilePond(
            FilePondPluginFileValidateType,
            FilePondPluginFileValidateSize,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit,
            FilePondPluginImageCrop
        )
    }
})
export default class AMediaInput extends Vue {
    $refs!: {
        pond: InstanceType<typeof VueFilePondComponent>;
    };

    files = [];
}
</script>

<style lang="scss">
@import 'filepond/dist/filepond.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
@import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

.filepond--wrapper {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    display: flex;

    .filepond--root {
        font-family: 'DM Sans', sans-serif;
        width: 100%;
        align-self: center;
        margin-bottom: 0;

        &:hover:not([data-disabled]) {
            color: $primary-color;

            .filepond--drop-label {
                color: $primary-color;
            }
        }

        .filepond--drop-label {
            color: $secondary-color;

            label {
                font-size: 0.875em;
                cursor: pointer;

                small {
                    font-size: 0.8em;
                }
            }
        }
    }
}
</style>
